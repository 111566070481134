import React, { useState, useRef, useEffect } from "react";
import { useQueryParam, getSearchParams } from "gatsby-query-params";

import axios from "axios";

import { Ring } from "react-awesome-spinners";
import { useForm } from "react-hook-form";

import TextError from "../../components/notifications/TextError";
import Success from "../../components/notifications/SuccessMessage";
import Error from "../../components/notifications/Error";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const CharityForm = () => {
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");

  // const [declare, setDeclare] = useState(false);
  //   const [agree, setAgree] = useState(false);
  const [guestName, setGuestName] = useState("");
  let guest;

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isSubmitted, isSubmitting, isValid },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      honey: "",
      name: guestName,
      attendance: "",
      table: "",
    },
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  });

  if (errors) {
    console.log("errors: ", errors);
  }
  console.log("isDirty", isDirty);
  console.log("isValid", isValid);
  console.log("isSubmitted", isSubmitted);

  console.log("getSearchParams", getSearchParams().guest); // Log all parameters
  //   console.log("name", name);
  let initialName = getSearchParams().guest;
  let initialTable = getSearchParams().table;

  setValue("name", initialName);
  setValue("table", initialTable);

  useEffect(() => {
    setGuestName("xyz from useEffect");
  }, []);

  const onSubmit = async data => {
    console.log("onSubmit");
    if (data.honey !== "") {
      setErrorText("Spam suspected");
      return;
    }

    delete data.honey;
    console.log("submitted data: ", data);

    let airtableURL;
    if (data) {
      airtableURL = `${process.env.GATSBY_API_STATIC}/charitydinner`;
      // airtableURL = `${process.env.REACT_APP_API_STATIC}/charitydinner`;
      // test
    }
    try {
      setErrorText("");
      setLoading(true);

      const res = await axios.post(airtableURL, data);
      console.log("status: ", res);
      if (res.data.statusCode === 400) {
        console.log("res.data", res.data);
        setErrorText(res.data.message);
        setLoading(false);
      } else if (res.data.statusCode === 200) {
        setResult("Submitted successfully");
        setLoading(false);
      }
    } catch (err) {
      console.log("catch error", err);
      setErrorText(err.message);
      setLoading(false);
    }
  };

  //   const agreeHandler = e => {
  //     // console.log("agree:", e.target.checked);
  //     setAgree(e.target.checked);
  //   };
  // const declareHandler = e => {
  //   // console.log("agree:", e.target.checked);
  //   setDeclare(e.target.checked);
  // };

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="Charity Dinner RSVP Form" />
        <div className="mx-3 mb-12 space-y-8 divide-y divide-gray-200 md:mx-auto md:max-w-xl lg:max-w-2xl">
          <div className="px-10 py-5 ">
            <h1 className="text-swa-3">45th Charity Gala Dinner 2022 RSVP</h1>
            <div className="mt-4 overflow-hidden rounded-lg aspect-w-5 aspect-h-4">
              <img
                className="absolute inset-0 object-cover w-full h-full"
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1663579861/charityDinnerPage/onzu6nsordscnoyy4auq.webp"
                alt=""
              />
            </div>
            {/* <h3>Under Maintenance. Please try again tomorrow</h3> */}
            {errorText && <Error msg={errorText} />}
            {isSubmitted && result && (
              <Success msg={result}>Successfully submitted</Success>
            )}
            {!isSubmitted && (
              <form
                className="max-w-xl mt-5 space-y-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Your information
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="name"
                          className={
                            errors.name
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Name{" "}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("name", {
                              required: true,
                              maxLength: 100,
                            })}
                            type="text"
                            name="name"
                            id="name"
                            disabled
                            className="block w-full max-w-lg text-xl text-gray-800 border-white "
                          />
                          {errors.name?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A name is required
                            </p>
                          )}
                          {errors.name?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="table"
                          className={
                            errors.name
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Table No.{" "}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("table", {
                              required: true,
                              maxLength: 100,
                            })}
                            type="text"
                            name="table"
                            id="table"
                            disabled
                            className="block w-full max-w-lg text-lg text-gray-800 border-white"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="attendance"
                          className={
                            errors.attendance
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Attendance
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="flex content-center justify-start w-full">
                            <select
                              {...register("attendance", {
                                required: true,
                                maxLength: 100,
                              })}
                              id="attendance"
                              name="attendance"
                              type="text"
                              autoComplete="off"
                              className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm appearance-none focus:ring-swa-1 focus:border-swa-3 sm:"
                            >
                              <option value="Attend">Yes, I will attend</option>
                              <option value="Not attending">
                                No, I am not able to attend
                              </option>
                            </select>
                          </div>
                          {errors.attendance?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A selection is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-8 space-y-6 divide-y divide-gray-200 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Important information
                      </h3>
                      <p className="text-gray-500">
                        Cocktail from 6pm to 6.30pm. Opening show and dinner
                        from 7pm. Dress code is formal.
                      </p>
                    </div>
                    {/* <div className="space-y-6 divide-y divide-gray-200 sm:space-y-5">
                      <div className="pt-6 sm:pt-5">
                        <div role="group" aria-labelledby="label-email">
                          <div className="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-baseline">
                            <div className="mt-4 sm:mt-0 sm:col-span-2">
                              <div className="max-w-lg space-y-4">
                                <div className="relative flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="declaration"
                                      name="declaration"
                                      type="checkbox"
                                      className="w-4 h-4 border-gray-300 rounded text-swa-1 focus:ring-swa-3"
                                      onChange={e => declareHandler(e)}
                                    />
                                  </div>
                                  <div className="ml-3 ">
                                    <label
                                      htmlFor="declarations"
                                      className="font-medium text-gray-700"
                                    >
                                      Important Information
                                    </label>

                                    <p className="text-gray-500">
                                      Cocktail from 6pm to 6.30pm. Opening show
                                      and dinner from 7pm. Dress code is formal.
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <div className="relative flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="offers"
                                      name="offers"
                                      type="checkbox"
                                      className="w-4 h-4 border-gray-300 rounded text-swa-1 focus:ring-swa-3"
                                      onChange={e => agreeHandler(e)}
                                    />
                                  </div>
                                  <div className="ml-3 ">
                                    <label
                                      htmlFor="offers"
                                      className="font-medium text-gray-700"
                                    >
                                      Agreement
                                    </label>

                                    <p className="mt-3 text-gray-500">
                                      I hereby declare that the information
                                      furnished on this application is true and
                                      accurate, I consent to having my personal
                                      data recorded in the relevant
                                      database&#40;s&#41; of Singapore Women's
                                      Association; to be contacted by the
                                      organization's relevant departments, and
                                      to accept the organization's decision on
                                      my application to be a volunteer.
                                    </p>
                                  </div>
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex justify-end">
                    {!isSubmitting && (
                      <button
                        type="submit"
                        disabled={!isDirty || errors.name}
                        className="inline-flex justify-center px-4 py-2 ml-3 font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-1 hover:bg-swa-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-3 disabled:opacity-30"
                      >
                        Submit
                      </button>
                    )}
                    {loading && <Ring color="rgb(112,48,140)" />}
                    {errorText !== "" && <TextError>{errorText}</TextError>}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CharityForm;

//
